.mainContainer {
    width:300px;
    min-width:300px;
    min-height:160px;
    height:160px;
    border-radius:20px;
    position:relative;
    /*background-image: url("/public/images/ferrari-thumbnail-bkg.png");*/
    background-size: contain;
    background-position: center;
    transition: border 0.1s ease;
    cursor: pointer;
}

.selected {
    border: 1px solid #fff;
}

.container {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 19px 19px 19px 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 30%);
    opacity: 50%;

    background: linear-gradient(90deg, rgba(0,0,0,0.7) 23%, rgb(0, 0, 0, 0.0) 100%);
    z-index: 0;
}

.contentContainer {
    width: 100%;
    height: 100%;
    padding: 20px;
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.90) 100%);
    border-radius:20px;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.description {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.description:hover {
    opacity: 1;
}

@media only screen and (max-width: 600px) {
    .mainContainer {
        width:230px;
        min-width:230px;
        height:150px;
        min-height:150px;

    }

    .contentContainer {
         padding: 16px;
         background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.85) 100%);
     }

    .container {
        clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 30%);
    }

    .titleContainer {
        gap: 8px;
    }
}

@media only screen and (max-width: 600px) {

}