/* ControlFeatures.module.css */

.container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.wrapper {
    position: relative;
    margin-left: 5vw;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
}

.phraseBox {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin: 5px 0;
    padding: 10px 20px;
    border: 1px solid white;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    opacity: 0.7;
    width: 370px;
    text-align: center;
    transition: all 0.5s ease-in-out;
}

.phraseBox[data-position="current"] {
    opacity: 1;
    transform: scale(1.1);
    transform-origin: top left;
    transition: all 0.5s ease-in-out;
}

.icon {
    width: 0px; /* Larghezza iniziale 0px */
    height: 20px;
    transition: width 0.5s ease-in-out; /* Anima la larghezza */
    margin-right: 10px; /* Spazio tra l'icona e il testo */
    flex-shrink: 0; /* Evita che l'immagine si ridimensioni con il testo */
}

.icon[data-current="true"] {
    width: 20px; /* Larghezza quando è corrente */
}

/* Media Query per Mobile */
@media (max-width: 600px) {

    .container {
        margin-bottom: 3vh;
    }

    .wrapper {
        flex-direction: row;
        overflow-x: visible; /* Disabilita lo scroll manuale */
        align-items: center;
        column-gap: 20px;
        margin-left: 2%;
        transition: transform 0.5s ease-in-out;
        width: 100%;
    }

    .phraseBox {
        flex-direction: row;
        align-items: center;
        width: auto;
        min-width: 410px;
        height: 100px;
        transform: scale(0.8,0.8);
        transition: transform 0.5s ease-in-out;
        transform-origin: bottom;
    }

    .phraseBox[data-position="current"] {
        opacity: 1;
        transform: scale(0.9);
        transition: all 0.5s ease-in-out;
        transform-origin: bottom;
    }

    .icon {
        width: 0px;
        margin-right: 0; /* Rimuovi margine destro */
    }

    .icon[data-current="true"] {
        width: 20px;
    }

    .container {
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
        display: flex;
        align-items: flex-end; /* Posiziona il contenuto in fondo alla sezione */
        justify-content: center;
    }
}
