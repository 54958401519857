video {
    opacity: 1;
    transition: all 0.5s ease-in-out; /* Durata della transizione */
}

.fadeOutR {
    opacity: 0; /* Animazione di scomparsa */
    transform: translate(1500px);
}

.fadeOutL {
    opacity: 0; /* Animazione di scomparsa */
    transform: translate(-1500px);
}

.fadeIn {
    opacity: 1; /* Animazione di comparsa */

}

@media (max-width: 600px) {
    .fadeOutR {
        transform: translate(500px);
    }

    .fadeOutL {
        transform: translate(-500px);
    }
}
