/* ImageCard.css */
.videoCard {
    position: relative;
    min-width: 300px;
    min-height: 450px;
    width: 300px;
    height: 450px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    scroll-snap-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cardContent {
    position: absolute; /* Occupa tutto lo spazio del genitore */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
}

.backgroundVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: opacity 0.3s ease;
    opacity: 1;
}

.videoCard:hover  {
    transform: translateY(-5px) scale(1.05); /* Solleva e ingrandisce la card */
    box-shadow: 0 16px 16px rgba(0, 0, 0, 0.5); /* Aumenta l'ombra per enfatizzare */
    z-index: 2; /* Porta la card in primo piano */
}

.videoCard:hover .title,
.videoCard:hover .description,
.videoCard:hover .titleContainer {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.title {
    position: absolute;
    padding-top: 32px;
    padding-left: 24px;
    top: 0;
    left: 0;
    color: #000;
    font-size: 20px;
    opacity: 100%;
    font-weight: bold;
    font-family: Poppins;
    z-index: 3;
    transition: all 0.3s ease;
}


.titleContainer {
    width: 100%;
    height: 70%;
    position: absolute;
    top: 0;
    left: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 35%);
    opacity: 25%;
    background-color: #fff;
    z-index: 2;
}

.description {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    color: #f2f2f2;
    font-size: 16px;
    line-height: 1.4;
    z-index: 1;
}

/* Responsive adjustments */

