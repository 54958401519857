.mainContainer {
    width:25vw;
    height:160px;
    border-radius:20px;
    border:2px solid #fff;
    position:relative;
}

.container {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 19px 19px 19px 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 25%);
    opacity: 50%;

    background: linear-gradient(90deg, rgba(255,255,255,0.3) 23%, rgba(255,255,255,1) 100%);
    z-index: 0;
}

.contentContainer {
    width: 100%;
    padding: 20px;
    position: relative;
    z-index: 5;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

@media only screen and (max-width: 600px) {
    .mainContainer {
        width:170px;
        height:160px;
        border-radius:20px;
        border:2px solid #fff;
        position:relative;
    }

    .container {
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 19px 19px 19px 0;
        clip-path: none;
        opacity: 100%;

        background: transparent;
        z-index: 0;
    }

    .contentContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 20px;
        padding-left: 30px;
        padding-right: 30px;

    }

    .titleContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: normal;
        text-align: center;
    }

}