:root {
    --scroll-speed: 400s; /* Cambia il valore per regolare la velocità */
}

.scrollingContainer {
    overflow: hidden;
    width: 100%;
    display: flex;
    white-space: nowrap;
    position: relative;
}

.scrollingContent {
    padding: 1.5rem;
    display: flex;
    animation: scroll var(--scroll-speed) linear infinite;
    gap: 50px;
}

.scrollingContent:hover {
    animation-play-state: paused;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.logo {
    width: 100px; /* Regola la dimensione dei loghi */
    margin: 0 20px;
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 90px;
}
