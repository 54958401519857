.mainContainer {
    width:100%;
    max-width:240px;
    max-height:400px;
    height: 100%;
    border-radius:20px;
    position:relative;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    background-color: #efefef;
}

.container {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 19px 19px 19px 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 5%);
    opacity: 50%;

    background: #fff;
    z-index: 0;
}

.contentContainer {
    width: 100%;
    height: 100%;
    padding: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius:20px;
    color:#000;
}

.title {
    margin-top: 5%;
    margin-bottom: 20%;
}

.winner {
    text-align: center;
}

.winnerImage {
    width: 30px;
}

@media only screen and (max-width: 600px) {
    .mainContainer {
        width:115px;
        height:200px;

        min-width:115px;
        min-height:200px;

        border-radius:10px;
    }

    .winner {
        font-size: 15px !important;
    }

    .image {
        width: 80px;
    }

    .winnerImage {
        width: 20px;
    }
}